import { SxProps } from '@mui/material';
import { globalSectionPadding, lightTheme } from '../../theme/index';

const { palette, spacing } = lightTheme;

const styles: Record<string, SxProps> = {
  container: {
    paddingX: globalSectionPadding,
    paddingY: spacing(2)
  },

  headingTitle: {
    color: palette.primary.main,
    textAlign: 'left',
    paddingBottom: spacing(1),
    width: '100%'
  }
};

export default styles;
