import React from 'react';
import { Box, Grid, Typography } from '@mui/material/';
import Markdown from 'markdown-to-jsx';
import { CardMain } from '../../components/CardMain';
import markdownOverrides from '../../utils/MarkdownOverrides';
import styles from './styles';

export interface LibAreaCardsSectionProps {
  cardsContent: Array<{
    icon: string;
    richText?: string;
    title?: string;
    subtitle?: string;
    linkText: string;
    href: string;
    target?: string;
    variant?: 'blue' | 'white';
    id?: string;
  }>;
  title?: string;
  cardData?: Array<{
    title?: string;
    LinkText?: string;
    imageCard?: string;
    imageCardAltText?: string;
    link?: string;
  }>;
}

function LibAreaCardsSection({
  title,
  cardData = []
}: LibAreaCardsSectionProps) {
  const overrides = {
    ...markdownOverrides,

    h2: {
      component: Typography,
      props: {
        variant: 'h2',
        sx: styles.headingTitle
      }
    },
    h3: {
      component: Typography,
      props: {
        variant: 'h3',
        sx: styles.headingTitle
      }
    }
  };

  return (
    <Box sx={styles.container}>
      <Markdown options={{ overrides }}>{title}</Markdown>
      <Grid container spacing={1}>
        {cardData.map((card) => (
          <Grid item xs={12} md={4} key={card.title}>
            <CardMain
              title={card.title}
              LinkText={card.LinkText}
              imageCard={card.imageCard}
              link={card.link}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export const AreaCardsSection = React.memo(LibAreaCardsSection);

export default AreaCardsSection;
