import { SxProps } from '@mui/material';
import { lightTheme } from '../../theme/index';

const { palette, breakpoints, spacing } = lightTheme;
const styles: Record<string, SxProps> = {
  baseState: {
    borderRadius: '0',
    transition: 'transform 0.5s',
    zIndex: '0',

    [breakpoints.up('md')]: {
      '&:hover': {
        transform: 'scale(1.05)',
        transformOrigin: 'center',
        boxShadow: 'rgb(0 0 0 / 22%) 0 1.25rem 1.25rem 0.3125rem',
        transition: 'transform 0.5s',
        borderRadius: '0',
        zIndex: '10'
      },
      '&:hover .ImageOverlayClass': {
        position: 'relative',
        '&::before': {
          content: '""',
          position: 'absolute',
          left: '0',
          top: '0',
          width: '100%',
          height: '101%',
          background:
            'linear-gradient(180deg, rgba(1, 113, 99, 0.1),rgba(1, 113, 99, 1))',
          border: 'none'
        }
      },
      '&:hover .BottomIconClass': {
        display: 'flex',
        marginTop: 'auto',
        marginLeft: 'auto'
      },
      '&:hover .MuiCardContent-root': {
        color: palette.grey['50'],
        backgroundColor: palette.grey['100'],
        /* padding: '0', */
        background:
          'linear-gradient(180deg, rgba(1, 113, 99, 0) 59.05%, #017163 100%), #017163'
      },
      '&:hover .HeadingTitleClass': {
        color: palette.grey['50'],
        // padding: '1.5rem 2.1875rem 0.625rem 1.5rem',
        wordWrap: 'normal'
      },
      '&:hover .LinkTitleClass': {
        color: palette.secondary.main,
        marginBottom: '0',
        wordWrap: 'normal'
      },
      '&:hover .VerticalBarClass': {
        borderColor: palette.secondary.main
      }
    }
  },

  '.MuiCardContent-root': {
    '&.MuiCardContent-root': {
      color: palette.primary.main,
      backgroundColor: palette.grey['100'],
      minHeight: '100%',
      padding: {
        xs: `${spacing(1)} ${spacing(0.5)}`,
        sm: spacing(1),
        md: spacing(1)
      }
    }
  },

  headingTitle: {
    color: palette.primary.main,
    wordWrap: 'normal'
  },

  linkTitle: {
    color: palette.grey.main,
    marginBottom: '0',
    wordWrap: 'normal'
  },

  '.MuiCardMedia-root': {
    backgroundColor: palette.grey['100'],
    padding: {
      xs: spacing(0.5),
      md: 0
    },
    objectFit: {
      xs: 'cover',
      ss: 'contain',
      sm: 'cover',
      md: 'contain'
    },
    height: '100%'
  },

  imageStyle: {
    height: {
      lg: '17rem',
      md: '15rem',
      sm: '100%',
      xs: '100%'
    }
  },

  footer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingRight: '0.625rem'
  },

  bottomBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(1),
    minHeight: {
      xs: '44vw',
      ss: '25vw',
      sm: '23vw',
      md: '7rem',
      lg: '6rem',
      ml: '5rem',
      xl: '4rem'
    }
  },

  footerBottomSubBox: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginTop: 'auto'
  },

  bottomIcon: {
    display: 'none'
  },
  verticalBar: {
    borderLeft: `2px solid ${palette.primary.main}`,
    mr: spacing(1),
    height: '100%'
  }
};

export default styles;
