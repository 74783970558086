import React from 'react';
import {
  CardActionArea,
  CardActionAreaProps,
  Box,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Typography
} from '@mui/material';
import Image from '../CImageComponent';
import ArrowBottomRight from '../../icons/ArrowBottomRight';
import styles from './styles';

export interface LibMainCardProps extends CardActionAreaProps {
  title?: string;
  LinkText?: string;
  imageCard?: string;
  imageCardAltText?: string;
  link?: string;
}

function LibCardMain({
  title,
  LinkText,
  imageCard,
  imageCardAltText = 'card picture',
  link
}: LibMainCardProps) {
  return (
    <Card sx={styles.baseState}>
      <CardActionArea href={link}>
        <Grid container>
          <Grid item xs={6} md={12}>
            <Box sx={styles.imageStyle} className="ImageOverlayClass">
              <CardMedia sx={styles['.MuiCardMedia-root']}>
                <div
                  style={{
                    position: 'relative',
                    width: '100%',
                    height: '100%'
                  }}
                >
                  <Image
                    src={imageCard}
                    alt={imageCardAltText}
                    fill
                    loading="lazy"
                    style={{ objectFit: 'cover' }}
                  />
                </div>
              </CardMedia>
            </Box>
          </Grid>
          <Grid item xs={6} md={12}>
            <CardContent sx={styles['.MuiCardContent-root']}>
              <Box sx={styles.bottomBox} className="BottomBox">
                <Typography
                  variant="h4"
                  component="p"
                  sx={styles.headingTitle}
                  className="HeadingTitleClass"
                >
                  {title}
                </Typography>
                <Box sx={styles.footerBottomSubBox}>
                  <Box sx={styles.footer}>
                    <Box sx={styles.verticalBar} className="VerticalBarClass" />
                    <Typography
                      variant="button"
                      sx={styles.linkTitle}
                      className="LinkTitleClass"
                    >
                      {LinkText}
                    </Typography>
                  </Box>
                  <Box sx={styles.bottomIcon} className="BottomIconClass">
                    <ArrowBottomRight />
                  </Box>
                </Box>
              </Box>
            </CardContent>
          </Grid>
        </Grid>
      </CardActionArea>
    </Card>
  );
}

export const CardMain = React.memo(LibCardMain);

export default CardMain;
