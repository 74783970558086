import React from 'react';
import { SvgIcon } from '@mui/material';

const SvgArrowBottomRight = (props) => (
  <SvgIcon {...props} title="ArrowBottomRight">
    <svg
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.366 2.073 18.51 18.218m0 0L18.51 2.073m0 16.145-16.144.001"
        stroke="#fff000"
        strokeWidth={3}
        strokeLinecap="round"
      />
    </svg>
  </SvgIcon>
);

export default SvgArrowBottomRight;
